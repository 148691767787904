<template>
  <div>
    <Loading v-if="loading" />
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-card-text
        class="pt-5"
      >
        <v-row>
          <v-col
            md="2"
            cols="12"
            class="mb-4 mt-0 pb-0"
          >
            <v-btn
              type="submit"
              @click="userData.discord_id === undefined ? handleLink('discord') : handleUnlink('discord')"
            >
              <v-icon size="20" dark left>
                {{ icons.mdiDiscord }}
              </v-icon>
              {{ userData.discord_id !== undefined ? 'Unlink Discord' : 'Link Discord' }}
            </v-btn>
          </v-col>
          <v-col
            md="2"
            cols="12"
            class="mb-4 mt-0 pb-0"
          >
            <v-btn
              type="submit"
              @click="userData.twitch_id === undefined ? handleLink('twitch') : handleUnlink('twitch')"
            >
              <v-icon size="20" dark left>
                {{ icons.mdiTwitch }}
              </v-icon>
              {{ userData.twitch_id !== undefined ? 'Unlink Twitch' : 'Link Twitch' }}
            </v-btn>
          </v-col>
          <v-col
            md="2"
            cols="12"
            class="mb-4 mt-0 pb-0"
          >
            <v-btn
              type="submit"
              @click="userData.twitter_id === undefined ? handleLink('twitter') : handleUnlink('twitter')"
            >
              <v-icon size="20" dark left>
                {{ icons.mdiTwitter }}
              </v-icon>
              {{ userData.twitter_id !== undefined ? 'Unlink Twitter' : 'Link Twitter' }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, computed, watch, reactive } from '@vue/composition-api'
import Vue from 'vue'
import Loading from '@/views/components/Loading.vue'
import axiosClient from '@/plugins/axios'
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
  mdiFacebook,
  mdiInstagram,
  mdiTwitter,
  mdiTwitch,
  mdiDiscord,
  mdiAccountGroup,
} from '@mdi/js'

export default {
  components: {
    Loading,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { root }) {
    let loading = ref(false)

    const handleLink = name => {
      const state = Math.floor(Math.random() * (999999999 - 0 + 1)) + 0
      localStorage.setItem('state', state)
      const challenge = Math.floor(Math.random() * (999999999 - 0 + 1)) + 0
      localStorage.setItem('challenge', challenge)
      let params = {
        state,
      }
      if (name == 'twitter') params['code_challenge'] = challenge
      root.$auth.oauth2(name, {
        params,
      })
    }

    const handleUnlink = name => {
      loading.value = true
      axiosClient
        .post(root.$API + '/profile/unlink', { type: name })
        .then(response => {
          loading.value = false
          root.$store.dispatch('user/setSocialintegrationId', { type: name, id: undefined })
          root.$store.dispatch('user/setSocialintegrationUsername', { type: name, username: undefined })
          root.vtoast.show('Account unlinking successful!')
        })
        .catch(e => {
          loading.value = false
          console.log(e)
          root.vtoast.show('Account unlinking failure!')
        })
    }

    return {
      loading,
      handleLink,
      handleUnlink,
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
        mdiFacebook,
        mdiInstagram,
        mdiTwitter,
        mdiTwitch,
        mdiDiscord,
        mdiAccountGroup,
      },
    }
  },
}
</script>
